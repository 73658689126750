import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons';
import { AppHeaderDropdown } from './Header/index';
import { routePaths } from '../shared/constants/api.constants';
import { HeaderNotifications } from './Header/HeaderNotifications';
import { NavLink } from 'react-router-dom';
import { useUser } from 'shared/hooks/useUser';

const AppHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state: any) => state.sidebarShow);
  const user = useUser();
  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <p className="h5 mb-0 m-l-5 fst-italic fw-bold text-opacity-25">
          Welcome, {user?.profile?.company}
        </p>
        <NavLink className="btn btn-outline-primary d-none" to={routePaths.shipments.new}>
          Add new shipment
        </NavLink>
        <CHeaderBrand className="mx-auto d-md-none">
          <CIcon icon="https://office.laasone.com/pdf-assets/logo_dark_mark.png" height={48} />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto"></CHeaderNav>
        <CHeaderNav>
          <CNavItem className="hidden">
            <CNavLink href="#">
              <CIcon icon={cilList} size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem className="hidden">
            <CNavLink href="#">
              <CIcon icon={cilEnvelopeOpen} size="lg" />
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <HeaderNotifications />
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      {/*<CHeaderDivider />*/}
    </CHeader>
  );
};

export default AppHeader;
