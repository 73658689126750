const translations = {
    OR: 'VÕI',
    LOGIN: 'Logi sisse',
    TOTAL: 'Kokku',
    FAILED_TO_LOG_IN: 'Sisselogimine ebaõnnestus',
    FAILED_TO_LOG_IN_BLOCKED: 'Konto on blokeeritud',
    CLOSE_ALERT: 'Sulge',
    CONNECTION_LOST: 'Ühendus katkenud',
    TRY_AGAIN: 'Viga. Palun proovi uuesti.',
    LOGIN_PAGE: {
        TITLE: 'Partner systems',
        TEXT: 'Logi oma kontole',
        BUTTON: 'Logi sisse',
        USERNAME: 'E-post',
        PASSWORD: 'Parool',
        FORGOT_PASSWORD: 'Unustasid parooli?',
        SIGN_UP: 'Rakendused, mida kasutavad meie partnerid, et saaksime oma kliente efektiivsemalt teenindada!',
        SIGN_UPTEXT: 'Vali millises rollis tahad registreerida',
        AS_SHIPPER: 'Registreeri saatjaks!',
        AS_CARRIER: 'Registreeri vedajaks!'
    },
    DRIVER_VIEW: {
        CHOOSE_LANGUAGE: 'Vali keel',
        CMR_ADDED: 'CMR LISATUD',
        CMR_UPLOADED: 'CMR üles laetud',
        COLLECTED: 'PEALEVÕETUD',
        DELIVERED: 'KOHALEVIIDUD',
        DELIVERY_REFERENCE: 'Viide',
        DROP_OFF: 'Tarne',
        FROM: 'kust',
        INSTRUCTIONS_LOADING: 'Juhised',
        INSTRUCTIONS_DELIVERY: 'Juhised',
        LOADING_REFERENCE: 'Viide',
        ORDER: 'Tellimus',
        PHOTO_OF_SIGNED_CMR: 'Foto allkirjastatud CMR-ist',
        PICKUP: 'Korje',
        SEND_CMR: 'SAADA CMR',
        TIME: 'KELL',
        TIME_LC: 'kell',
        TO: 'kuhu',
    },
    PICKUP_AND_DELIVERY_DATES: 'Laadimisajad',
    PICKUP_FROM: 'Korje',
    DELIVERY_TO: 'Tarne',
    EARLIEST_PICKUP: 'Varaseim korje kuupäev',
    LATEST_PICKUP: 'Hiliseim korje kuupäev',
    EARLIEST_DELIVERY: 'Varaseim tarne kuupäev',
    LATEST_DELIVERY: 'Hiliseim tarne kuupäev',
    PricesOffered: 'Oled pakkumise esitanud',
    WaitingPrices: 'Palun saada oma pakkumine',
    Rejected: 'Keeldusid pakkumast',
    Expired: 'Aegunud',
    SPOT_REQUEST: {
        TITLE: 'Hinnapäring',
        TITLE_DESC: 'Palun vasta meie hinnapäringule, sisestades meile oma hinnad',
        DETAILS: 'Detailid',
        CARRIER: 'Partner',
        OPENED_UNTIL: 'Aktiivne kuni',
        STATUS: 'Staatus',
        SHIPMENT: 'Saadetised',
        CONTACT_PERSON: 'Kontakt',
        CONTACT_PERSON_EMAIL: 'E-post',
        COMMENT: 'Märkused',
        YOUR_PRICES: 'Hinnad',
        PRICE: 'Hind',
        DELIVERY_TIME: 'Transiidi päevad',
        PRICE_COMMENT: 'Kommentaar',
        PRICE_VALID_UNTIL: 'Hind aktiivne kuni',
        ADD_PRICE: 'Lisa hind',
        MAKE_OFFER: 'Saada pakkumine',
        REJECT: 'Keeldu',
        ASTRICS_REQUIRED: '* kohustuslikud väljad',
        SPOT_REQUEST_MODAL: 'Keeldu pakkumast hindasid',
        REJECT_REASON: 'Põhjus',
        SUBMIT_SUCCESS: 'Hinnad edukalt sisestatud. Täname!',
        ORDERER: "Tellija"
    },
    ITEMS_IN_THE_SHIPMENT: 'Saadetise kaubad',
    SHIPMENT: {
        TRANSPORT_DELIVERY_WITH_TAIL_LIFT_TRUCK:'Tarne tagaluugiga autoga',
        TRANSPORT_WITH_TAIL_LIFT_TRUCK: 'Korje tagaluugiga autoga',
        TRANSPORT_DANGEROUS_GOODS: 'Sisaldab ohtlikut kaupa',
        ITEM: {
            SELECT_TYPE: 'Vali tüüp',
            DETAILS: {
                PC: 'tk',
                TYPE: 'Liik',
                LENGTH: 'Pikkus',
                LENGTH_TOOLTIP: 'Pakendi pikkus on sellel pakendi tüübil standardiseeritud',
                WIDTH: 'Laius',
                WIDTH_TOOLTIP: 'Pakendi laius on sellel pakendi tüübil standardiseeritud',
                HEIGHT: 'Kõrgus',
                VOLUME: 'Maht',
                WEIGHT_PC: 'Kaal/tk',
                TOTAL_WEIGHT: 'Kogukaal',
                LDM: 'LDM',
                LDM_TOOLTIP: 'Laadimismeeter (jooksev meeter haagise pikkusest täislaiuses ja -kõrguses)',
                REFERENCE_NUMBER: 'Viitenumber',
                PRODUCT_DESCRIPTION: 'Kauba kirjeldus',
                UN_NO: 'UN kood',
                UN_NO_TOOLTIP: 'ÜRO tunnusnumber, mis identifitseerib ohtlikud ained ja esemed',
                UN: 'UN',
                UN_CODE: 'UN kood',
                PG: 'Pakendigrupp',
                PG_TOOLTIP: 'Ohtlikud kaubad jagatakse oma ohtlikkuse taseme järgi 3 peamisse gruppi',
                CLASS: 'Klass',
                CLASS_TOOLTIP: 'Ohtlike kaupade klassifikatsiooni numbrid',
                DANGEROUS_GOODS: 'Ohtlik kaup',
                COLD_FROZEN: 'Jahutatud/külmutatud',
                STACKABLE: 'Virnastatav',
                DELICATE: 'Õrn kaup',
                MIN_TEMP: 'Min temp',
                MIN_TEMP_TOOLTIP: 'Minimaalne temperatuur, mida kaup vajab transpordiks',
                MAX_TEMP: 'Max temp',
                MAX_TEMP_TOOLTIP: 'Maksimaalne temperatuur, mida kaup vajab transpordiks',
                PROPER_SHIPPING_NAME: 'Ohtliku veose tunnusnimetus',
                PROPER_SHIPPING_NAME_TOOLTIP: 'Nimetus ohtlike kaupade omaduste ja koostise kirjeldamiseks',
                PROPER_SHIPPING_NAME_PLACEHOLDER: 'Must pulber, kokkusurutud, või püssirohi',
            },
            TYPE: {
                PALLET: 'Alus',
                PACKAGE: 'Pakk',
                TRUCK: 'Täiskoorem',
                OTHER: 'Muu',
                CONTAINER: 'Konteiner'
            },
            SUBTYPE: {
                EUR_PALLET: 'EUR alus',
                FIN_PALLET: 'FIN alus',
                PALLET: 'Alus',
                PACKAGE: 'Pakk',
                BOX: 'Kast',
                FULL_TRUCK_LOAD: 'Täiskoorem',
                LONG_PALLET: 'Pikk alus',
                HALF_PALLET: 'Poolik alus',
                LDM: 'LDM',
                CONTAINER_20_DC: '20’DC',
                CONTAINER_40_DC: '40’DC',
                CONTAINER_40_HC: '40’HC',
                CONTAINER_OTHER: 'Muu konteiner',
            },
            CLASS: {
                EXPLOSIVES: 'Lõhkeained',
                GASES: 'Gaasid',
                FLAMMABLE_LIQUIDS: 'Kergestisüttivad vedelikud',
                FLAMMABLE_SOLIDS: 'Kergestisüttivad tahked ained',
                OXIDIZERS: 'Oksüdeerivad ained',
                TOXIC_SUBSTANCES: 'Mürgised ained',
                RADIOACTIVE: 'Radioaktiivsed materjalid',
                CORROSIVES: 'Sööbivad ained',
                MISCELLANOUS: 'Muud ohtlikud ained ja esemed',
            },
            PG: {
                PG1: 'Pakendirühm I: väga ohtlikud',
                PG2: 'Pakendirühm II: mõõdukalt ohtlikud',
                PG3: 'Pakendirühm III: vähem ohtlikud',
            },
        },
    },
    SWITCH: {
        YES: 'JAH',
        NO: 'EI',
    },
    DATEPICKER_PLACEHOLDER: "dd.mm.yyyy",
    'This username already exists': 'See kasutajanimi on juba kasutusel',
    'This company already exists': 'Sellise registrikoodiga ettevõte on juba registreeritud'
}
export default translations;
