import React, { FC, ReactElement, Suspense, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { useUser } from 'shared/hooks/useUser';
import { SystemAlertContext, useSystemAlert } from 'shared/hooks/useSystemAlert';
import { IS_DEMO } from 'shared/constants/appConstants';
import { EnvironmentPanel, Loader, SystemAlert } from 'components';
import AuthenticatedApp from './AuthenticatedApp';
import { RouteProps, routesPublic } from '../routes';
import { generateKey } from '../shared/functions/generateKey';
import { NotFound } from '../scenes';
import InvitationApp from './InvitationApp';

const RouteLoadingFallback = (
  <div>
    <Loader cover noBackground color="secondary" />
  </div>
);

const AppContents: FC = (): ReactElement => {
  const user = useUser();
  const { t } = useTranslation();
  const alertContext = useContext(SystemAlertContext);
  const { showSystemSuccessMessage } = useSystemAlert();
  const [wasPreviouslyLoggedIn, setWasPreviouslyLoggedIn] = useState(false);

  useEffect(() => {
    // notify user after logout
    if (wasPreviouslyLoggedIn && !user.isLoggedIn) {
      showSystemSuccessMessage(t('YOU_ARE_LOGGED_OUT'));
    }
    setWasPreviouslyLoggedIn(user.isLoggedIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isLoggedIn]);

  /* const hideLogoutMessage = () => {
    hideSystemMessage();
  }; */
  const isInvitationView = window.location.pathname.includes('invitation');
  const routeMapper = (
    { path, component, exact, environment, ...rest }: RouteProps,
    index: number,
  ): ReactElement =>
    component && (
      <Route
        key={generateKey(index, (path.length ? path[0] : path).replace('/', '-'))}
        path={path}
        component={component}
        exact={exact}
        {...rest}
      />
    );

  const renderRoutes = (
    <Suspense fallback={RouteLoadingFallback}>
      <Switch>
        {routesPublic.map(routeMapper)}
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
  return (
    <>
      {IS_DEMO && <EnvironmentPanel />}
      {alertContext.isSystemAlertVisible && (
        <SystemAlert onClose={alertContext.hideSystemAlert} color={alertContext.systemAlertColor}>
          {alertContext.message}
        </SystemAlert>
      )}
      {isInvitationView && (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <InvitationApp />
        </BrowserRouter>
      )}
      {!isInvitationView && user.isLoggedIn && (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <AuthenticatedApp />
        </BrowserRouter>
      )}
      {!isInvitationView && !user.isLoggedIn && (
        <BrowserRouter basename={process.env.PUBLIC_URL}>{renderRoutes}</BrowserRouter>
      )}
    </>
  );
};

export default AppContents;
