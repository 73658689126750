import React from 'react';
import CIcon from '@coreui/icons-react';
import {
  cilApplications,
  cilInstitution,
  cilListRich,
  cilMoney,
  cilPhone,
  cilTruck,
} from '@coreui/icons';
import { CNavItem } from '@coreui/react';

const _nav = [
  {
    component: CNavItem,
    name: 'Overview',
    to: '/dashboard',
    icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
    badge: {},
  },
  {
    component: CNavItem,
    name: 'Shipments list',
    to: '/dashboard2',
    icon: <CIcon icon={cilListRich} customClassName="nav-icon" />,
    badge: {},
  },
  {
    component: CNavItem,
    name: 'Rfq',
    to: '/dashboard3',
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
    badge: {},
  },
  {
    component: CNavItem,
    name: 'Manage',
    to: undefined,
    icon: undefined,
    badge: {},
    items: [
      {
        component: CNavItem,
        name: 'Profile',
        to: '/dashboard3',
        icon: <CIcon icon={cilInstitution} customClassName="nav-icon" />,
        badge: {},
      },
      {
        component: CNavItem,
        name: 'Contacts',
        to: '/dashboard3',
        icon: <CIcon icon={cilPhone} customClassName="nav-icon" />,
        badge: {},
      },
      {
        component: CNavItem,
        name: 'Fleet',
        to: '/dashboard3',
        icon: <CIcon icon={cilTruck} customClassName="nav-icon" />,
        badge: {},
      },
    ],
  },
  {
    component: CNavItem,
    name: 'Payment',
    to: undefined,
    icon: undefined,
    badge: {},
    items: [
      {
        component: CNavItem,
        name: 'Invoices',
        to: '/dashboard3',
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
        badge: {},
      },
    ],
  },
];

export default _nav;
