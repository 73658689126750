const translations = {
    OR: 'OR',
    LOGIN: 'Login',
    TOTAL: 'Yhteensä',
    FAILED_TO_LOG_IN: 'Failed to log in',
    FAILED_TO_LOG_IN_BLOCKED: 'Account is blocked',
    CLOSE_ALERT: 'Close',
    LOGIN_PAGE: {
        TITLE: 'Login',
        TEXT: 'Sign In to your account',
        BUTTON: 'Login',
        USERNAME: 'Username',
        PASSWORD: 'Password',
        FORGOT_PASSWORD: 'Forgot password?',
        SIGN_UP: 'Sign up',
        SIGN_UPTEXT: 'Heavy transportation has never been this easy',
        AS_SHIPPER: 'Register as Shipper!',
        AS_CARRIER: 'Register as Carrier!'
    },
    DRIVER_VIEW: {
        CHOOSE_LANGUAGE: 'Valitse kieli',
        CMR_ADDED: 'CMR LISÄTTY',
        CMR_UPLOADED: 'CMR LADATTU',
        COLLECTED: 'LASTATTU',
        DELIVERED: 'TOIMITETTU',
        DELIVERY_REFERENCE: 'Viite',
        DROP_OFF: 'Toimitus',
        FROM: 'mistä',
        INSTRUCTIONS_LOADING: 'Ohjeet',
        INSTRUCTIONS_DELIVERY: 'Ohjeet',
        LOADING_REFERENCE: 'Viite',
        ORDER: 'Tilaus',
        PHOTO_OF_SIGNED_CMR: 'Kuva allekirjoitetusta CMR-stä',
        PICKUP: 'Nouto',
        SEND_CMR: 'LÄHETÄ CMR',
        TIME: 'Kello',
        TIME_LC: 'kello',
        TO: 'mihin',
    },
    PICKUP_AND_DELIVERY_DATES: 'Nouto- ja toimitustiedot',
    PICKUP_FROM: 'Nouto',
    DELIVERY_TO: 'Toimitus',
    EARLIEST_PICKUP: 'Varhaisin nouto',
    LATEST_PICKUP: 'Viimeisin nouto',
    EARLIEST_DELIVERY: 'Varhaisin toimitus',
    LATEST_DELIVERY: 'Viimeisin toimitus',
    PricesOffered: 'Olet tehnyt tarjouksen',
    WaitingPrices: 'Lähetä tarjouksesi',
    Rejected: 'Hylätty',
    Expired: 'Päättynyt',
    SPOT_REQUEST: {
        TITLE: 'Spot-hintapyyntö',
        TITLE_DESC: 'Vastaa pyyntöömme ja tarjoa hintasi',
        DETAILS: 'Yksityiskohdat',
        CARRIER: 'Kuljetusliike',
        OPENED_UNTIL: 'Avoinna saakka',
        STATUS: 'Status',
        SHIPMENT: 'Lähetys',
        CONTACT_PERSON: 'Yhteystiedot',
        CONTACT_PERSON_EMAIL: 'Sähköposti',
        COMMENT: 'Huomautukset',
        YOUR_PRICES: 'Tarjoa hinta',
        PRICE: 'Hinta',
        DELIVERY_TIME: 'Kuljetusaika',
        PRICE_COMMENT: 'Huomautus',
        PRICE_VALID_UNTIL: 'Hinta voimassa asti',
        ADD_PRICE: 'Lisää hinta',
        MAKE_OFFER: 'Lähetä tarjous',
        REJECT: 'Hylkää',
        ASTRICS_REQUIRED: '* ovat pakollisia kenttiä',
        SUBMIT_SUCCESS: 'Tarjotut hinnat. Kiitos!',
        ORDERER: "Tilaaja"
    },
    ITEMS_IN_THE_SHIPMENT: 'Viimeisin toimitus',
    SHIPMENT: {
        TRANSPORT_DELIVERY_WITH_TAIL_LIFT_TRUCK:'Toimitus takalavatrukilla',
        TRANSPORT_WITH_TAIL_LIFT_TRUCK: 'Nouto takalavatrukilla',
        TRANSPORT_DANGEROUS_GOODS: 'Sisältää vaarallisia aineita',
        ITEM: {
            SELECT_TYPE: 'Valitse tyyppi',
            DETAILS: {
                PC: 'kpl',
                TYPE: 'Tyyppi',
                LENGTH: 'Pituus',
                LENGTH_TOOLTIP: 'Pituus on kiinteä valitulle tuotetyypille',
                WIDTH: 'Leveys',
                WIDTH_TOOLTIP: 'Leveys on kiinteä valitulle tuotetyypille',
                HEIGHT: 'Korkeus',
                VOLUME: 'Volyymi',
                WEIGHT_PC: 'Paino/kpl',
                TOTAL_WEIGHT: 'Kokonaispaino',
                LDM: 'Lavametriä',
                LDM_TOOLTIP: 'Lavametrit (perävaunun pituuden juoksumetrit täydellä leveydellä ja korkeudella). Tämä lasketaan automaattisesti pituuden, leveyden ja määrän perusteella, mutta voit kirjoittaa eri arvon tarvittaessa.',
                REFERENCE_NUMBER: 'Viitenumero',
                PRODUCT_DESCRIPTION: 'Tuotteen kuvaus',
                UN_NO: 'UN no',
                UN_NO_TOOLTIP: 'Numero, joka tunnistaa vaaralliset aineet, vaaralliset aineet ja esineet',
                UN: 'UN',
                UN_CODE: 'UN-kood',
                PG: 'PG',
                PG_TOOLTIP: 'Vaaralliset aineet jaetaan 3 pakkausryhmään niiden aiheuttaman vaaran asteen mukaan',
                CLASS: 'Luokka',
                CLASS_TOOLTIP: 'Vaarallisten aineiden luokitusnumero',
                DANGEROUS_GOODS: 'Vaaralliset tavarat',
                COLD_FROZEN: 'Kylmä/jäädytetty',
                STACKABLE: 'Pinottava',
                DELICATE: 'Herkkä',
                MIN_TEMP: 'Min. lämpötila',
                MIN_TEMP_TOOLTIP: 'Tavaroiden vähimmäislämpötila, jota ne tarvitsevat kuljetukseen',
                MAX_TEMP: 'Max lämpötila',
                MAX_TEMP_TOOLTIP: 'Tavaroiden maksimilämpötila, jota ne tarvitsevat kuljetukseen',
                PROPER_SHIPPING_NAME: 'Oikea tuotteen nimi',
                PROPER_SHIPPING_NAME_TOOLTIP: 'Nimi, joka kuvaa vaarallisten aineiden vaaraominaisuuksia ja koostumusta',
                PROPER_SHIPPING_NAME_PLACEHOLDER: 'Musta jauhe, puristettu tai ruuti',
            },
            TYPE: {
                PALLET: 'Lava',
                PACKAGE: 'Paketti',
                TRUCK: 'Kuorma-auto',
                OTHER: 'Muu',
                CONTAINER: 'Kontti'
            },
            SUBTYPE: {
                EUR_PALLET: 'EUR-lava',
                FIN_PALLET: 'FIN-lava',
                PALLET: 'Lava',
                PACKAGE: 'Paketti',
                BOX: 'Laatikko',
                FULL_TRUCK_LOAD: 'Täysi kuorma',
                LONG_PALLET: 'Pitkä lava',
                HALF_PALLET: 'Puolilava',
                LDM: 'Lavametriä',
                CONTAINER_20_DC: '20’DC',
                CONTAINER_40_DC: '40’DC',
                CONTAINER_40_HC: '40’HC',
                CONTAINER_OTHER: 'Muu',
            },
            CLASS: {
                EXPLOSIVES: 'Räjähteet',
                GASES: 'Kaasut',
                FLAMMABLE_LIQUIDS: 'Syttyvät nesteet',
                FLAMMABLE_SOLIDS: 'Syttyvät kiinteät aineet',
                OXIDIZERS: 'Syttyvät kiinteät aineet',
                TOXIC_SUBSTANCES: 'Toksiset aineet',
                RADIOACTIVE: 'Radioaktiivinen',
                CORROSIVES: 'Syövyttävät aineet',
                MISCELLANOUS: 'Sekalaista',
            },
            PG: {
                PG1: 'Pakkausryhmä I: korkea vaara',
                PG2: 'Pakkausryhmä II: keskivaara',
                PG3: 'Pakkausryhmä III: vähäinen vaara',
            },
        },
    },
    SWITCH: {
        YES: 'KYLLÄ',
        NO: 'EI',
    },
    DATEPICKER_PLACEHOLDER: "dd.mm.yyyy",
    'This username already exists': 'This username already exists',
    'This company already exists': 'This company already exists'
}
export default translations;