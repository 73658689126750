import { CDropdown, CDropdownMenu, CDropdownToggle, CDropdownHeader } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilBell } from '@coreui/icons';
import React, { useState } from 'react';
import clsx from 'clsx';

export const HeaderNotifications = () => {
  const [notRead] = useState(0);

  return (
    <span className="notification-dropdown d-none">
      <CDropdown className="mt-2" autoClose="outside">
        <CDropdownToggle
          caret={false}
          color="light"
          className="position-relative mr-10 bg-white no-border"
        >
          <CIcon icon={cilBell} size="lg" className={clsx(notRead > 0 && 'shake')} />
          {notRead > 0 && (
            <span
              className="badge bg-danger rounded position-absolute"
              style={{ top: -12, right: -12 }}
            >
              {notRead}
            </span>
          )}
        </CDropdownToggle>
        <CDropdownMenu className="pt-0">
          <CDropdownHeader className="bg-light dark:bg-white dark:bg-opacity-10">
            Notifications
          </CDropdownHeader>
        </CDropdownMenu>
      </CDropdown>
    </span>
  );
};
